import { Observable } from './observable';
import { authBloc, LoginState } from './auth';

export class LoginFlow extends Observable<string> {
  public static readonly screenBrandIntro = 'brandIntro';
  public static readonly screenLanding = 'landing';
  public static readonly screenSignin = 'signin';
  public static readonly screenSignup = 'signup';
  public static readonly screenConfirmSignup = 'confirmSignup';
  public static readonly screenConfirmReset = 'confirmReset';
  public static readonly screenConfirmExpired = 'confirmExpired';

  constructor() {
    super('loginFlow', true, true);
    authBloc.loginState.subscribe(this._updateState, this);
    this._updateState();
  }

  _updateState = () => {
    switch (authBloc.loginState.current) {
      case LoginState.NeedSignin:
        this.notify(LoginFlow.screenSignin);
        break;
      case LoginState.NeedRecoverCode:
        this.notify(LoginFlow.screenConfirmReset);
        break;
      case LoginState.NeedSignupCode:
        this.notify(LoginFlow.screenConfirmSignup);
        break;
      case LoginState.NeedExpired:
        this.notify(LoginFlow.screenConfirmExpired);
        break;
      default:
        this.notify(
          authBloc.getEmail()
            ? LoginFlow.screenLanding
            : LoginFlow.screenBrandIntro,
        );
    }
  };

  continue = () => {
    this.log('continue');
    if (this.current == LoginFlow.screenBrandIntro)
      this.notify(LoginFlow.screenLanding);
  };

  signin = () => {
    this.notify(LoginFlow.screenSignin);
  };

  signup = () => {
    this.notify(LoginFlow.screenSignup);
  };

  cancel = () => {
    this.log('cancel');
    if (this.current == LoginFlow.screenConfirmReset || this.current == LoginFlow.screenConfirmExpired) this.notify(this.prev);
    else if (this.current == LoginFlow.screenConfirmSignup)
      this.notify(LoginFlow.screenSignup);
  };
}

export const loginFlow = new LoginFlow();
