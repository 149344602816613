export type Observer<T> = (value:T|undefined)=>void;

export type Logger = (...optionalParams: any[])=>void;

interface ObserverContext{
  observer:Function;
  context:any;
}

export class Observable <T>{
    _name:string;
    _subscribers:Observer<T>[] = [];
    _contexts:ObserverContext[] = [];
    _current?:T;
    _notifyAlways?:boolean;
    _needPrev?:boolean;
    _prev?:T;
    _disableLog?:boolean;

    public static logger:Logger;

    constructor(name:string, notifyAlways?:boolean,needPrev?:boolean, disableLog?:boolean){
      this._name = name;
      this._notifyAlways = notifyAlways;
      this._needPrev = needPrev;
      this._disableLog = disableLog;
    }

    public subscribe(observer: Observer<T>, context:any) {
      const boundObserver = observer.bind(context);
      const index = this._contexts.findIndex((existing)=>existing.observer == observer && existing.context == context);
      if(index == -1){
          this._subscribers.push(boundObserver);
          this._contexts.push({observer:observer,context:context});
      }
    }

    public unsubscribe(observer: Observer<T>, context:any) {
      const index = this._contexts.findIndex((existing)=>existing.observer == observer && existing.context == context);
      if(index != -1){
          this._subscribers.splice(index,1);
          this._contexts.splice(index,1);
      }
    }

    public get current():T|undefined{
      return this._current;
    }

    public get prev():T|undefined{
      return this._prev;
    }

    public notify(value:T|undefined){
      if(this._notifyAlways || value != this._current){
        this.log('Changed',value);
        if(this._needPrev && this._current != value)
          this._prev = this._current;
        this._current = value;
        this._subscribers.forEach((observer)=>{
          try{
            observer(value);
          }
          catch(error){
            this.log('Unhandled Error in Observer',error);
          }
        });
      }
    }

    public log(message?: any, ...optionalParams: any[]){
      if(Observable.logger && !this._disableLog)
        Observable.logger(this._name,message,...optionalParams);
    }
};