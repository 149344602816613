import { API } from "aws-amplify";
import { AuthBloc } from "./auth";

export class HaiApi{
    post = async(path:string, params?:any):Promise<any>=>{
        return API.post(AuthBloc.appRoot, path?.startsWith('/') ? path:"/"+path, params ?? {});
    }

    get = async(path:string,params?:any):Promise<any>=>{
        return API.get(AuthBloc.appRoot, path?.startsWith('/') ? path:"/"+path, params ?? {});
    }

    put = async(path:string, params?:any):Promise<any>=>{
        return API.put(AuthBloc.appRoot, path?.startsWith('/') ? path:"/"+path, params ?? {});
    }

    del = async(path:string, params?:any):Promise<any>=>{
        return API.del(AuthBloc.appRoot, path?.startsWith('/') ? path:"/"+path, params ?? {});
    }
}

export const haiApi = new HaiApi();