import AsyncStorage from '@react-native-async-storage/async-storage';
import { CognitoUser, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Amplify, { API, Auth, Storage } from 'aws-amplify';
import { Observable } from './observable';
import NetInfo, {
  NetInfoState,
  NetInfoStateType,
} from '@react-native-community/netinfo';
import config from './hai.json';

//Amplify.Logger.LOG_LEVEL = 'DEBUG';

export interface EnvConfig {
  env: string;
  region: string;
  appPoolId: string;
  appClientId: string;
  apiBaseEndpoint: string;
  firmwareBucket: string;
  appIdPool:string;
  mockAuthorizer?:string;
}

export const envConfig:EnvConfig = Object.values(config)[0];

export enum AuthState {
  Loading,
  NotLoggedIn,
  LoggedIn,
  LoggedOut,
}

export enum LoginState {
  NeedSignupCode,
  NeedSignin,
  NeedRecoverCode,
  NeedExpired
}

export type LogOutHandler = (global?:boolean) => Promise<any>;

export class AuthBloc {
  public static readonly appRoot = 'hai';
  public static readonly attributeEmail = 'email';
  public static readonly attributeFirstName = 'given_name';
  public static readonly attributeLastName = 'family_name';

  public static readonly passwordMinLength = 8;
  public static readonly passwordMaxLength = 30;
  public static readonly passwordRules = `minlength: ${AuthBloc.passwordMinLength}; maxlength: ${AuthBloc.passwordMaxLength}; required: lower; required: upper; required: digit; required: [ !"#$%&'()*+,./:;<=>?@[\^_\`{|}~]];`;

  private static readonly keyEmail = 'email';

  _email: string = '';
  _currentUser?: CognitoUser;
  _userAttributes: CognitoUserAttribute[] = [];
  _mockUserId?:string;

  public authState = new Observable<AuthState>('authState');
  public loginState = new Observable<LoginState>('loginState', true);
  public connectionState = new Observable<boolean>('connectionState');

  public logoutHandler: LogOutHandler | undefined;

  constructor() {
    Auth.configure({
      region: envConfig.region,
      userPoolId: envConfig.appPoolId,
      userPoolWebClientId: envConfig.appClientId,
      identityPoolId: envConfig.appIdPool
    });
    API.configure({
      endpoints: [
        {
          name: AuthBloc.appRoot,
          region: envConfig.region,
          endpoint: `https://${envConfig.apiBaseEndpoint}/${envConfig.env}`,
          custom_header: async () => {
            const token = envConfig.mockAuthorizer ? this._mockUserId: (await Auth.currentSession())
              .getIdToken()
              .getJwtToken();
            return { Authorization: envConfig.mockAuthorizer ? token : `Bearer ${token}` };
          },
        },
      ],
    });
    Storage.configure({
      AWSS3: {
        region: envConfig.region, //OPTIONAL -  Amazon service region
      }
    });

    NetInfo.fetch().then((state)=>{
      this._onNetworkStateChanged(state);
      NetInfo.addEventListener(this._onNetworkStateChanged);
    });    
  }

  _onNetworkStateChanged = (state: NetInfoState) => {
    this.connectionState.log('Netstate is ', state.isInternetReachable);
    //Don't do any state transition unless we are sure
    if (state.type != NetInfoStateType.unknown && state.isInternetReachable != null) {
      this.connectionState.notify(state.isInternetReachable);
      if(this.authState.current == undefined && state.isInternetReachable)
        this._load();
    }
  };

  _load = async () => {
    if(envConfig.mockAuthorizer){
      this.authState.notify(AuthState.NotLoggedIn);
      return;
    } 

    if (this.authState.current == AuthState.Loading) return;

    this.authState.notify(AuthState.Loading);
    this.loginState.notify(undefined);

    //Load saved state
    try {
      this._email = (await AsyncStorage.getItem(AuthBloc.keyEmail)) ?? '';
    } catch (error) {
      this.authState.log('Failed to load state', AuthBloc.keyEmail, error);
    }

    //Load saved session
    let currentUser: CognitoUser | undefined;

    try {
      currentUser = await Auth.currentUserPoolUser();
    } catch (error) {
      this.authState.log('Failed to load saved user', error);
    }

    //Load user information if valid session    
    if (currentUser){
      await this._loadUser(currentUser);

      if (this.authState.current != AuthState.LoggedIn)
        this.authState.notify(undefined);
    }
    else
      this.authState.notify(AuthState.NotLoggedIn);
  };

  _loadUser = async (user: CognitoUser) => {
    if(envConfig.mockAuthorizer) return;

    let success: Function, fail: Function;
    const completer = new Promise<CognitoUserAttribute[]>((resolve, reject) => {
      success = resolve;
      fail = reject;
    });

    user.getUserAttributes((error, attributes) => {
      if (error) {
        this.authState.log('!Failed to get user attributes', error);
        fail(error);
      } else if (attributes) {
        this.authState.log('User attributes', attributes);
        success([...attributes]);
      }
    });

    try{
      this._userAttributes = await completer;
    }
    catch(error){
      this.authState.log('Failed to load user data', error);
      return;
    }

    this._currentUser = user;
    this._email = this.getAttribute(AuthBloc.attributeEmail);

    this.authState.notify(AuthState.LoggedIn);

    try {
      await AsyncStorage.setItem(AuthBloc.keyEmail, this._email);
    } catch (error) {
      this.authState.log('!Failed to save state', error, AuthBloc.keyEmail);
    }
  };

  isMock = ():boolean => envConfig.mockAuthorizer ? true:false;

  getEmail = (): string => {
    return this._email;
  };

  getUserId = (): string => {
    return this._currentUser?.getUsername() ?? (this.isMock() ? this._mockUserId:undefined) ?? '';
  };

  getAttribute = (name: string): string => {
    return (
      this._userAttributes.find(attrib => attrib.Name == name)?.Value ?? ''
    );
  };

  logIn = async (email: string, password: string): Promise<void> => {
    email = email.trim();
    if (this.authState.current == undefined ||
      this.authState.current == AuthState.Loading ||
      this.authState.current == AuthState.LoggedIn
    )
      throw new Error('Invalid State');

    this._email = email;

    if(envConfig.mockAuthorizer){
      this._mockUserId = password;
      this._email = email;
      this.authState.notify(AuthState.LoggedIn);
      return;
    }

    try {
      const user: CognitoUser = await Auth.signIn(email, password);
      //this.authState.log(JSON.stringify(user));
      this._loadUser(user);
    } catch (error:any) {
      this.authState.log('*Failed to signin', error);
      if (error.code == 'UserNotConfirmedException') {
        this.loginState.notify(LoginState.NeedSignupCode);
      } else throw error;
    }
  };

  signUp = async (
    email: string,
    password: string,
    firstName: string,
    lastName: string,
  ): Promise<void> => {
    if (envConfig.mockAuthorizer || this.authState.current == undefined || this.authState.current == AuthState.Loading || this.authState.current == AuthState.LoggedIn)
      throw new Error('Invalid State');

    const attributes: any = {};
    attributes[AuthBloc.attributeFirstName] = firstName;
    attributes[AuthBloc.attributeLastName] = lastName;

    this._email = email;

    this._userAttributes.length = 0;
    this._userAttributes = [
      new CognitoUserAttribute({ Name: AuthBloc.attributeEmail, Value: email }),
      new CognitoUserAttribute({
        Name: AuthBloc.attributeFirstName,
        Value: firstName,
      }),
      new CognitoUserAttribute({
        Name: AuthBloc.attributeLastName,
        Value: lastName,
      }),
    ];

    try {
      const result = await Auth.signUp({
        username: email,
        password: password,
        attributes: attributes,
      });
      this.authState.log(JSON.stringify(result));
      this.loginState.notify(
        result.userConfirmed
          ? LoginState.NeedSignin
          : LoginState.NeedSignupCode,
      );
    } catch (error) {
      this.authState.log('*Failed to signup', error);
      throw error;
    }
  };

  confirmSignup = async (code: string): Promise<void> => {
    if (envConfig.mockAuthorizer || 
      this.authState.current == undefined ||
      this.authState.current == AuthState.Loading ||
      this.authState.current == AuthState.LoggedIn ||
      this.loginState.current != LoginState.NeedSignupCode ||
      !this._email
    )
      throw new Error('Invalid State');

    try {
      const result = await Auth.confirmSignUp(this._email, code);
      this.authState.log(JSON.stringify(result));
      this.loginState.notify(LoginState.NeedSignin);
    } catch (error) {
      this.authState.log('*Failed to verify Email', error);
      throw error;
    }
  };

  resendSignup = async (): Promise<void> => {
    if (envConfig.mockAuthorizer || 
      this.authState.current == undefined ||
      this.authState.current == AuthState.Loading ||
      this.authState.current == AuthState.LoggedIn ||
      this.loginState.current != LoginState.NeedSignupCode ||
      !this._email
    )
      throw new Error('Invalid State');

    try {
      const result = await Auth.resendSignUp(this._email);
      this.authState.log(JSON.stringify(result));
    } catch (error) {
      this.authState.log('!Failed to send verification email', error);
      throw error;
    }
  };

  forgotPassword = async (email?: string, isResetRequired: boolean = false): Promise<void> => {
    this._email = email ?? this._email;
    this.loginState.log(
      'Forgot passowrd',
      'passed',
      email,
      this._email,
      this.authState.current,
    );

    if (envConfig.mockAuthorizer || this.authState.current == undefined || this.authState.current == AuthState.Loading || !this._email)
      throw new Error('Invalid State');

    try {
      const result = await Auth.forgotPassword(this._email);
      this.authState.log('Forgot password result', result);
      if (this.authState.current != AuthState.LoggedIn) {
        isResetRequired
          ? this.loginState.notify(LoginState.NeedExpired)
          : this.loginState.notify(LoginState.NeedRecoverCode);
      }
    } catch (error) {
      this.authState.log('!Failed to send password recovery', error);
      throw error;
    }
  };

  resetPassword = async (code: string, password: string): Promise<void> => {
    if (envConfig.mockAuthorizer || this.authState.current == undefined || this.authState.current == AuthState.Loading || !this._email)
      throw new Error('Invalid State');

    try {
      await Auth.forgotPasswordSubmit(
        this._email,
        code,
        password,
      );
      if (this.authState.current != AuthState.LoggedIn) {
        this.loginState.notify(LoginState.NeedSignin);
      }
    } catch (error) {
      this.authState.log('*Failed to reset password', error);
      throw error;
    }
  };

  changePassword = async (
    currentPassword: string,
    newPassword: string,
  ): Promise<void> => {
    if (envConfig.mockAuthorizer || this.authState.current == undefined || this.authState.current != AuthState.LoggedIn)
      throw new Error('Invalid State');

    try {
      const result = await Auth.changePassword(
        this._currentUser,
        currentPassword,
        newPassword,
      );
      this.authState.log('changePassword', JSON.stringify(result));
    } catch (error) {
      this.authState.log('*Failed to change password', error);
      throw error;
    }
  };

  logOut = async (global?:boolean): Promise<void> => {
    if (envConfig.mockAuthorizer || this.authState.current == undefined || this.authState.current != AuthState.LoggedIn)
      throw new Error('Invalid State');

    if (this.logoutHandler) await this.logoutHandler(global);

    try {
      const result = await Auth.signOut({ global: global });
      this.authState.log('signOut',global,JSON.stringify(result));
      this._currentUser = undefined;
      this._userAttributes.length = 0;
      if(global)
        this._email = '';
      this.authState.notify(AuthState.LoggedOut);
      this.loginState.notify(LoginState.NeedSignin);
    } catch (error) {
      this.authState.log('!Failed to signout',global, error);
      throw error;
    }
  };
}

export const authBloc = new AuthBloc();